import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Form, Image } from 'react-bootstrap';
import AdhyapakAssistant from './AdhyapakAssistant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faComment, faEye, faPaperclip, faSmile, faTrash, faEdit, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { fetchDiscussions, createDiscussion, addReply, uploadAttachment, deleteDiscussion, deleteReply } from './communityApi';
import '../../assets/css/Community.css';

const Community = ({ user }) => {
  const [discussions, setDiscussions] = useState([]);
  const [newDiscussion, setNewDiscussion] = useState({ title: '', content: '', attachments: [] });
  const [newReply, setNewReply] = useState({ content: '', attachments: [] });
  const [replyingTo, setReplyingTo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDiscussions();
  }, []);

  const loadDiscussions = async () => {
    try {
      const data = await fetchDiscussions();
      setDiscussions(data);
    } catch (error) {
      console.error('Error loading discussions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (event, isReply = false) => {
    const files = Array.from(event.target.files);
    const uploadedAttachments = [];

    for (const file of files) {
      try {
        const attachment = await uploadAttachment(file);
        uploadedAttachments.push(attachment);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    if (isReply) {
      setNewReply(prev => ({
        ...prev,
        attachments: [...prev.attachments, ...uploadedAttachments]
      }));
    } else {
      setNewDiscussion(prev => ({
        ...prev,
        attachments: [...prev.attachments, ...uploadedAttachments]
      }));
    }
  };

  const handleCreateDiscussion = async (e) => {
    e.preventDefault();
    try {
      const discussion = await createDiscussion({
        ...newDiscussion,
        author: {
          id: user.id,
          name: user.name,
          role: user.role,
          avatar: user.avatar || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.name}`
        }
      });
      setDiscussions(prev => [discussion, ...prev]);
      setNewDiscussion({ title: '', content: '', attachments: [] });
    } catch (error) {
      console.error('Error creating discussion:', error);
    }
  };

  const handleAddReply = async (discussionId) => {
    try {
      const reply = await addReply(discussionId, {
        ...newReply,
        author: {
          id: user.id,
          name: user.name,
          role: user.role,
          avatar: user.avatar || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.name}`
        }
      });

      setDiscussions(prev =>
        prev.map(d =>
          d.id === discussionId
            ? { ...d, replies: [...d.replies, reply] }
            : d
        )
      );
      setNewReply({ content: '', attachments: [] });
      setReplyingTo(null);
    } catch (error) {
      console.error('Error adding reply:', error);
    }
  };

  const handleDeleteDiscussion = async (discussionId) => {
    if (window.confirm('Are you sure you want to delete this discussion?')) {
      try {
        await deleteDiscussion(discussionId);
        setDiscussions(prev => prev.filter(d => d.id !== discussionId));
      } catch (error) {
        console.error('Error deleting discussion:', error);
      }
    }
  };

  const handleDeleteReply = async (discussionId, replyId) => {
    if (window.confirm('Are you sure you want to delete this reply?')) {
      try {
        await deleteReply(discussionId, replyId);
        setDiscussions(prev =>
          prev.map(d =>
            d.id === discussionId
              ? { ...d, replies: d.replies.filter(r => r.id !== replyId) }
              : d
          )
        );
      } catch (error) {
        console.error('Error deleting reply:', error);
      }
    }
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  if (loading) {
    return <div className="text-center mt-5">Loading discussions...</div>;
  }

  return (
    <div className="community-container">
      <AdhyapakAssistant user={user} />
      <h2 className="mb-4">Community Discussions</h2>

      {/* New Discussion Form */}
      <Card className="new-discussion-form">
        <Card.Body>
          <Form onSubmit={handleCreateDiscussion}>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={newDiscussion.title}
                onChange={(e) => setNewDiscussion(prev => ({ ...prev, title: e.target.value }))}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newDiscussion.content}
                onChange={(e) => setNewDiscussion(prev => ({ ...prev, content: e.target.value }))}
                required
              />
            </Form.Group>
            <div className="attachment-upload">
              <Form.Control
                type="file"
                multiple
                onChange={(e) => handleFileUpload(e, false)}
                style={{ display: 'none' }}
                id="discussion-file-upload"
              />
              <label htmlFor="discussion-file-upload" style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faPaperclip} className="me-2" />
                Attach Files
              </label>
            </div>
            {newDiscussion.attachments.length > 0 && (
              <div className="discussion-attachments mb-3">
                {newDiscussion.attachments.map((attachment, index) => (
                  <div key={index} className="attachment-preview">
                    {attachment.type === 'image' ? (
                      <Image src={attachment.url} alt={attachment.name} fluid />
                    ) : (
                      <div>{attachment.name}</div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <Button type="submit" variant="primary">Create Discussion</Button>
          </Form>
        </Card.Body>
      </Card>

      {/* Discussions List */}
      {discussions.map(discussion => (
        <Card key={discussion.id} className="discussion-card">
          <Card.Body>
            <div className="discussion-header">
              <div className="author-info">
                <Image src={discussion.author.avatar} className="author-avatar" />
                <div>
                  <div>{discussion.author.name}</div>
                  <div className="timestamp">{formatDate(discussion.timestamp)}</div>
                </div>
              </div>
              {(user.role === 'admin' || user.id === discussion.author.id) && (
                <div className="admin-controls">
                  <Button
                    className="delete-button admin-button"
                    onClick={() => handleDeleteDiscussion(discussion.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  {user.role === 'admin' && (
                    <Button className="pin-button admin-button">
                      <FontAwesomeIcon icon={faThumbtack} />
                    </Button>
                  )}
                </div>
              )}
            </div>

            <h3 className="discussion-title">{discussion.title}</h3>
            <div className="discussion-content">{discussion.content}</div>

            {discussion.attachments.length > 0 && (
              <div className="discussion-attachments">
                {discussion.attachments.map((attachment, index) => (
                  <div key={index} className="attachment-preview">
                    {attachment.type === 'image' ? (
                      <Image src={attachment.url} alt={attachment.name} fluid />
                    ) : (
                      <div>{attachment.name}</div>
                    )}
                  </div>
                ))}
              </div>
            )}

            <div className="discussion-footer">
              <div className="discussion-tags">
                {discussion.tags.map((tag, index) => (
                  <span key={index} className="tag">{tag}</span>
                ))}
              </div>
              <div className="discussion-actions">
                <button className="action-button">
                  <FontAwesomeIcon icon={faThumbsUp} />
                  <span>{discussion.upvotes}</span>
                </button>
                <button className="action-button">
                  <FontAwesomeIcon icon={faComment} />
                  <span>{discussion.replies.length}</span>
                </button>
                <button className="action-button">
                  <FontAwesomeIcon icon={faEye} />
                  <span>{discussion.views}</span>
                </button>
              </div>
            </div>

            {/* Replies Section */}
            <div className="replies-section">
              {discussion.replies.map(reply => (
                <div key={reply.id} className="reply-card">
                  <div className="discussion-header">
                    <div className="author-info">
                      <Image src={reply.author.avatar} className="author-avatar" />
                      <div>
                        <div>{reply.author.name}</div>
                        <div className="timestamp">{formatDate(reply.timestamp)}</div>
                      </div>
                    </div>
                    {(user.role === 'admin' || user.id === reply.author.id) && (
                      <Button
                        className="delete-button admin-button"
                        onClick={() => handleDeleteReply(discussion.id, reply.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    )}
                  </div>
                  <div className="discussion-content">{reply.content}</div>
                  {reply.attachments.length > 0 && (
                    <div className="discussion-attachments">
                      {reply.attachments.map((attachment, index) => (
                        <div key={index} className="attachment-preview">
                          {attachment.type === 'image' ? (
                            <Image src={attachment.url} alt={attachment.name} fluid />
                          ) : (
                            <div>{attachment.name}</div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}

              {/* Reply Form */}
              {replyingTo === discussion.id ? (
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  handleAddReply(discussion.id);
                }}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={newReply.content}
                      onChange={(e) => setNewReply(prev => ({ ...prev, content: e.target.value }))}
                      placeholder="Write your reply..."
                      required
                    />
                  </Form.Group>
                  <div className="attachment-upload">
                    <Form.Control
                      type="file"
                      multiple
                      onChange={(e) => handleFileUpload(e, true)}
                      style={{ display: 'none' }}
                      id={`reply-file-upload-${discussion.id}`}
                    />
                    <label htmlFor={`reply-file-upload-${discussion.id}`} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faPaperclip} className="me-2" />
                      Attach Files
                    </label>
                  </div>
                  {newReply.attachments.length > 0 && (
                    <div className="discussion-attachments mb-3">
                      {newReply.attachments.map((attachment, index) => (
                        <div key={index} className="attachment-preview">
                          {attachment.type === 'image' ? (
                            <Image src={attachment.url} alt={attachment.name} fluid />
                          ) : (
                            <div>{attachment.name}</div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="d-flex gap-2">
                    <Button type="submit" variant="primary">Submit Reply</Button>
                    <Button variant="secondary" onClick={() => {
                      setReplyingTo(null);
                      setNewReply({ content: '', attachments: [] });
                    }}>Cancel</Button>
                  </div>
                </Form>
              ) : (
                <Button
                  variant="outline-primary"
                  onClick={() => setReplyingTo(discussion.id)}
                >
                  Reply to Discussion
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default Community;
