import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Layout/Header';
import LandingPage from './components/Landing/LandingPage';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import Dashboard from './components/Dashboard/Dashboard';
import ExamTakingPage from './components/Dashboard/ExamTakingPage';
import Statistics from './components/Dashboard/Statistics';
import Community from './components/Dashboard/Community';

const AppRoutes = () => {
    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);

    return (
        <Router>
            <Header user={user} setUser={setUser} />
            <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login setUser={setUser} />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/dashboard" element={user ? <Dashboard user={user} /> : <LandingPage />} />
                <Route path="/exam/:testId" element={<ExamTakingPage user={user} />} />
                <Route path="/statistics" element={user ? <Statistics user={user} /> : <LandingPage />} />
                <Route path="/dashboard/statistics" element={user ? <Statistics user={user} /> : <LandingPage />} />
                <Route path="/community" element={user ? <Community user={user} /> : <LandingPage />} />
                <Route path="/dashboard/community" element={user ? <Community user={user} /> : <LandingPage />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;
