// Dummy data for community discussions
export const dummyDiscussions = [
  {
    id: 1,
    title: "How to prepare for JEE Advanced?",
    content: "I'm planning to take JEE Advanced next year. What are the best strategies and resources to prepare?",
    author: {
      id: 101,
      name: "Rahul Kumar",
      role: "user",
      avatar: "https://api.dicebear.com/7.x/avataaars/svg?seed=Rahul"
    },
    timestamp: "2024-01-25T14:30:00Z",
    attachments: [
      {
        id: 1,
        type: "image",
        url: "https://example.com/study-plan.jpg",
        name: "My Current Study Plan"
      }
    ],
    replies: [
      {
        id: 1,
        content: "Focus on NCERT books first, then move to advanced reference books 📚",
        author: {
          id: 102,
          name: "Dr. Sharma",
          role: "admin",
          avatar: "https://api.dicebear.com/7.x/avataaars/svg?seed=DrSharma"
        },
        timestamp: "2024-01-25T15:00:00Z",
        attachments: []
      }
    ],
    tags: ["JEE", "Preparation", "Study Tips"],
    upvotes: 15,
    views: 150
  },
  {
    id: 2,
    title: "Doubt in Physics Wave Optics",
    content: "Can someone explain the concept of interference in wave optics? I'm having trouble understanding the phase difference calculation.",
    author: {
      id: 103,
      name: "Priya Singh",
      role: "user",
      avatar: "https://api.dicebear.com/7.x/avataaars/svg?seed=Priya"
    },
    timestamp: "2024-01-24T10:15:00Z",
    attachments: [
      {
        id: 2,
        type: "image",
        url: "https://example.com/wave-diagram.jpg",
        name: "Wave Diagram"
      }
    ],
    replies: [],
    tags: ["Physics", "Wave Optics", "Doubt"],
    upvotes: 8,
    views: 95
  }
];

// API functions that will be replaced with real API calls later
export const fetchDiscussions = async () => {
  return dummyDiscussions;
};

export const createDiscussion = async (discussionData) => {
  // This will be replaced with a real API call
  return {
    ...discussionData,
    id: Math.random().toString(36).substr(2, 9),
    timestamp: new Date().toISOString(),
    replies: [],
    upvotes: 0,
    views: 0
  };
};

export const addReply = async (discussionId, replyData) => {
  // This will be replaced with a real API call
  return {
    ...replyData,
    id: Math.random().toString(36).substr(2, 9),
    timestamp: new Date().toISOString()
  };
};

export const uploadAttachment = async (file) => {
  // This will be replaced with a real API call
  return {
    id: Math.random().toString(36).substr(2, 9),
    type: file.type.startsWith('image/') ? 'image' : 'file',
    url: URL.createObjectURL(file),
    name: file.name
  };
};

export const deleteDiscussion = async (discussionId) => {
  // This will be replaced with a real API call
  return true;
};

export const deleteReply = async (discussionId, replyId) => {
  // This will be replaced with a real API call
  return true;
};
