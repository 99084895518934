import React, { useState, useEffect } from 'react';
import { fetchExamNames, fetchExamDetails, createTest, getAllTests, getTestPayload, deleteTest } from './api';
import { FaTrash, FaPlay, FaPause, FaCheck, FaClock } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import AdhyapakAssistant from './AdhyapakAssistant';

const Dashboard = ({ user }) => {
    const [exams, setExams] = useState([]);
    const [selectedExam, setSelectedExam] = useState(null);
    const [testName, setTestName] = useState('');
    const [chatLog, setChatLog] = useState([]);
    const [sectionData, setSectionData] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});
    const [testList, setTestList] = useState([]);
    const [creatingNewTest, setCreatingNewTest] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [deletingTest, setDeletingTest] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const allTests = await getAllTests(user.email);
                setTestList(allTests);
                setIsLoading(false);

                if (location.state?.testComplete) {
                    const { testResults, message } = location.state;
                    setToastMessage(message);
                    setShowToast(true);
                    window.history.replaceState({}, document.title);
                }
            } catch (error) {
                console.error('Error fetching test data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [user.email, location]);

    const handleCreateNewTest = async () => {
        try {
            const examNames = await fetchExamNames();
            setExams(examNames);
            setCreatingNewTest(true);
            setChatLog([
                { type: 'system', content: 'Please select an exam to create a new test.', style: { backgroundColor: '#e0e0e0' } },
                ...examNames.map(name => ({
                    type: 'system',
                    content: name,
                    style: { backgroundColor: '#f9f9f9' },
                    action: () => handleExamSelection(name),
                }))
            ]);
        } catch (error) {
            console.error('Error fetching exam names:', error);
        }
    };

    const handleExamSelection = async (examName) => {
        try {
            const examDetails = await fetchExamDetails(examName);
            setSelectedExam(examDetails);
            setSectionData(examDetails.Subjects || {});
            setChatLog([
                ...chatLog,
                { type: 'system', content: `You selected: ${examName}`, style: { backgroundColor: '#e0e0e0' } },
                { type: 'system', content: 'Please provide the details for the test.', style: { backgroundColor: '#e0e0e0' } }
            ]);
        } catch (error) {
            console.error('Error fetching exam details:', error);
        }
    };

    const handleTopicChange = (section, topic) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [section]: {
                ...(prevOptions[section] || { topics: {}, difficulty: {} }),
                topics: {
                    ...(prevOptions[section]?.topics || {}),
                    [topic]: !prevOptions[section]?.topics?.[topic],
                },
            },
        }));
    };

    const handleDifficultyChange = (section, difficulty) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [section]: {
                ...(prevOptions[section] || { topics: {}, difficulty: {} }),
                difficulty: {
                    ...(prevOptions[section]?.difficulty || {}),
                    [difficulty]: !prevOptions[section]?.difficulty?.[difficulty],
                },
            },
        }));
    };

    const handleQuestionNumberChange = (section, value) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [section]: {
                ...(prevOptions[section] || { topics: {}, difficulty: {}, questionCount: 0 }),
                questionCount: Math.min(value, sectionData[section]?.['Number Of Questions'] || 0),
            },
        }));
    };

    const handleSubmit = async () => {
        if (!testName.trim()) {
            setToastMessage('Test name is required');
            setShowToast(true);
            return;
        }
    
        const finalJson = {
            "TestName": testName,
            "Name": selectedExam?.Name || "",
            "Subjects": {}
        };
    
        Object.entries(selectedOptions).forEach(([section, { topics, questionCount, difficulty }]) => {
            finalJson.Subjects[section] = {
                "Topics": Object.keys(topics).filter(topic => topics[topic]),
                "Number Of Questions": questionCount || 0,
                "Difficulty Level": Object.keys(difficulty).filter(level => difficulty[level])
            };
        });
    
        setLoading(true);
    
        try {
            await createTest(user.email, finalJson);
            setToastMessage('Test created successfully');
            setShowToast(true);
    
            const updatedTests = await getAllTests(user.email);
            setTestList(updatedTests);
    
            setTimeout(() => {
                setCreatingNewTest(false);
            }, 2000);
        } catch (error) {
            console.error('Error creating test:', error);
            setToastMessage('Error creating test. Please try again.');
            setShowToast(true);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteTest = async (testId, emailId) => {
        setDeletingTest(testId);
    
        try {
            const result = await deleteTest(emailId, testId);
            if (result && result.message) {
                setToastMessage(result.message);
            } else {
                setToastMessage('Test deleted successfully');
            }
    
            const updatedTests = await getAllTests(emailId);
            setTestList(updatedTests);
            setShowToast(true);
        } catch (error) {
            console.error('Error deleting test:', error);
            setToastMessage('Error deleting test. Please try again.');
            setShowToast(true);
        } finally {
            setDeletingTest(null);
        }
    };

    const handleTestAction = (test) => {
        if (test.Status === 'In progress' || test.Status === 'Not started' || test.Status === 'Completed') {
            navigate(`/exam/${test.TestId}`, { state: { email: user.email } });
        }
    };

    const renderTestCard = (test) => {
        const getStatusBadgeClass = (status) => {
            switch(status) {
                case 'In progress': return 'status-badge status-in-progress';
                case 'Completed': return 'status-badge status-completed';
                default: return 'status-badge';
            }
        };

        const getActionButton = (status) => {
            const btnClass = 'btn ' + (status === 'Completed' ? 'btn-secondary' : 'btn-primary');
            const icon = status === 'In progress' ? <FaPlay /> : 
                        status === 'Completed' ? <FaCheck /> : <FaPlay />;
            const text = status === 'In progress' ? 'Resume' : 
                        status === 'Completed' ? 'Review' : 'Start';
            
            return (
                <button className={btnClass} onClick={() => handleTestAction(test)}>
                    {icon} <span className="ml-2">{text}</span>
                </button>
            );
        };

        return (
            <div key={test.TestId} className="test-card animate-fade-in">
                <div className="test-header">
                    <h3 className="test-name">{test.TestName}</h3>
                    <span className={getStatusBadgeClass(test.Status)}>{test.Status}</span>
                </div>
                
                <div className="test-meta">
                    <div><FaClock /> Created: {new Date(test.CreationTime).toLocaleString()}</div>
                    <div>Last Updated: {new Date(test.LastUpdateTime).toLocaleString()}</div>
                </div>

                {test.Status === 'In progress' && (
                    <div className="test-progress">
                        <div className="progress-bar" style={{width: `${(test.CurrentQuestion / test.QuestionCount) * 100}%`}} />
                    </div>
                )}

                {test.Status === 'Completed' && (
                    <div className="score-display animate-fade-in">
                        <span>Score: </span>
                        <span className="score-number">{test.Score}</span>
                        <div className="test-stats">
                            <div className="stat-item">
                                <span className="stat-label">Correct:</span>
                                <span className="stat-value correct">{test.CorrectCount}</span>
                            </div>
                            <div className="stat-item">
                                <span className="stat-label">Incorrect:</span>
                                <span className="stat-value incorrect">{test.IncorrectCount}</span>
                            </div>
                            <div className="stat-item">
                                <span className="stat-label">Unattempted:</span>
                                <span className="stat-value">{test.UnattemptedCount}</span>
                            </div>
                            {test.TimeTaken && (
                                <div className="stat-item">
                                    <span className="stat-label">Time:</span>
                                    <span className="stat-value">{Math.floor(test.TimeTaken / 60)}m {test.TimeTaken % 60}s</span>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className="test-actions">
                    {getActionButton(test.Status)}
                    <button 
                        className="btn btn-danger"
                        onClick={() => handleDeleteTest(test.TestId, user.email)}
                        disabled={deletingTest === test.TestId}
                    >
                        {deletingTest === test.TestId ? 'Deleting...' : <FaTrash />}
                    </button>
                </div>
            </div>
        );
    };

    const renderTestCreationForm = () => {
        return (
            <div className="test-form animate-fade-in">
                <div className="form-section">
                    <h2 className="form-section-title">Create New Test</h2>
                    {!selectedExam ? (
                        <div className="exam-selection">
                            <p className="form-description">Select an exam type to begin:</p>
                            <div className="exam-options">
                                {exams.map((name, index) => (
                                    <button
                                        key={index}
                                        className="btn btn-secondary"
                                        onClick={() => handleExamSelection(name)}
                                    >
                                        {name}
                                    </button>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="exam-info">
                                <p className="exam-description">{selectedExam.Description}</p>
                            </div>
                            
                            <div className="form-group">
                                <label className="form-label" htmlFor="testName">Test Name</label>
                                <input
                                    id="testName"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter test name"
                                    value={testName}
                                    onChange={(e) => setTestName(e.target.value)}
                                    required
                                />
                                {testName === '' && showToast && (
                                    <div className="form-error">Please enter a test name</div>
                                )}
                            </div>

                            {Object.entries(sectionData).map(([section, data]) => (
                                <div key={section} className="form-section">
                                    <h3 className="form-section-title">{section}</h3>
                                    <div className="form-grid">
                                        <div className="form-group">
                                            <label className="form-label">Topics</label>
                                            <div className="checkbox-group">
                                                {data.Topics.map(topic => (
                                                    <label key={topic} className="checkbox-label">
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => handleTopicChange(section, topic)}
                                                            checked={!!selectedOptions[section]?.topics?.[topic]}
                                                        />
                                                        <span>{topic}</span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label">Difficulty Level</label>
                                            <div className="checkbox-group">
                                                {['Easy', 'Medium', 'Hard'].map(difficulty => (
                                                    <label key={difficulty} className="checkbox-label">
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => handleDifficultyChange(section, difficulty)}
                                                            checked={!!selectedOptions[section]?.difficulty?.[difficulty]}
                                                        />
                                                        <span>{difficulty}</span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label">Number of Questions</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min="0"
                                                max={data['Number Of Questions']}
                                                value={selectedOptions[section]?.questionCount || data['Number Of Questions']}
                                                onChange={(e) => handleQuestionNumberChange(section, e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="form-actions">
                                <button 
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {loading ? 'Creating...' : 'Create Test'}
                                </button>
                                <button 
                                    className="btn btn-secondary"
                                    onClick={() => setCreatingNewTest(false)}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (showToast) {
            const timer = setTimeout(() => {
                setShowToast(false);
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [showToast]);

    return (
        <div className="dashboard-container">
            <AdhyapakAssistant 
                testCreationData={creatingNewTest && selectedExam ? {
                    topic: selectedExam.Name,
                    subjects: Object.entries(selectedOptions).map(([section, data]) => ({
                        name: section,
                        topics: Object.keys(data.topics || {}).filter(topic => data.topics[topic])
                    }))
                } : null}
                user={user}
            />
            <div className="dashboard-header">
                <h1 className="dashboard-title">Your Tests</h1>
                <button className="btn btn-primary" onClick={handleCreateNewTest}>
                    Create New Test
                </button>
            </div>

            <div className="dashboard-content">
                {isLoading ? (
                    <div className="loading-spinner">Loading...</div>
                ) : creatingNewTest ? (
                    renderTestCreationForm()
                ) : testList.length === 0 ? (
                    <div className="empty-state">
                        <p>No tests available. Create your first test to get started!</p>
                        <button className="btn btn-primary" onClick={handleCreateNewTest}>
                            Create New Test
                        </button>
                    </div>
                ) : (
                    <div className="test-grid">
                        {testList.map(renderTestCard)}
                    </div>
                )}
            </div>

            {showToast && (
                <div className={`toast-message ${toastMessage.includes('success') ? 'toast-success' : 'toast-error'}`}>
                    {toastMessage}
                    <button 
                        className="toast-close"
                        onClick={() => setShowToast(false)}
                        aria-label="Close"
                    >
                        ×
                    </button>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
