import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Card, ProgressBar, Form, Spinner, Alert } from 'react-bootstrap';
import { getTestPayload, saveTest, submitTest, getAllTests } from './api';
import AdhyapakAssistant from './AdhyapakAssistant';

const ExamTakingPage = ({ user }) => {
  const { testId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [testStatus, setTestStatus] = useState('');
  const [likes, setLikes] = useState({});
  const [notes, setNotes] = useState([]);
  const [savingProgress, setSavingProgress] = useState(false);
  const [examScore, setExamScore] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');

  useEffect(() => {
    const fetchQuestionsAndStatus = async () => {
      if (!user || !user.email) return;
  
      setLoading(true);
  
      try {
        const testPayload = await getTestPayload(user.email, testId);
        const allTestsResponse = await getAllTests(user.email);
  
        const allTests = Array.isArray(allTestsResponse) ? allTestsResponse : [];
        if (allTests.length === 0) {
          console.error("No tests found in response.");
          setQuestions([]);
          setTestStatus("No tests found.");
          return;
        }
  
        const currentTest = allTests.find((test) => test.TestId === testId);
        if (!currentTest) {
          console.error(`Test with TestId ${testId} not found in API response.`);
          setTestStatus("Not started");
          return;
        }
  
        const questionStats = currentTest?.QuestionStats
          ? JSON.parse(currentTest.QuestionStats)
          : {};
  
        setQuestions(testPayload || []);
        setTestStatus(currentTest.Status || "Not started");
        setLikes(currentTest.Likes || {});
        setNotes(currentTest.Notes || []);
      } catch (error) {
        console.error("Error fetching questions or test status:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (testId) fetchQuestionsAndStatus();
  }, [testId, user]);

  const handleOptionSelect = (optionIndex) => {
    if (testStatus !== "Completed") {
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        const currentQuestion = { ...updatedQuestions[currentQuestionIndex] };
        const updatedSelections = [...(currentQuestion.SelectedOptions || [])];
  
        if (updatedSelections.includes(optionIndex)) {
          updatedSelections.splice(updatedSelections.indexOf(optionIndex), 1);
        } else {
          updatedSelections.push(optionIndex);
        }
  
        currentQuestion.SelectedOptions = updatedSelections;
        updatedQuestions[currentQuestionIndex] = currentQuestion;
  
        return updatedQuestions;
      });
    }
  };
  
  const isQuestionAnswered = (index) => selectedOptions[index]?.length > 0;

  const handleSubmitTest = async () => {
    setSubmitLoading(true);
    try {
      await handleSaveProgress();
      const submitResponse = await submitTest(user.email, testId);

      if (submitResponse && submitResponse.Status === "Completed") {
        const testResults = {
          score: submitResponse.Score || "0/0",
          correctCount: submitResponse.CorrectCount || 0,
          incorrectCount: submitResponse.IncorrectCount || 0,
          unattemptedCount: submitResponse.UnattemptedCount || 0,
          timeTaken: submitResponse.LastUpdateTime - submitResponse.CreationTime || 0,
          testId: testId
        };

        // Calculate score percentage for Adhyapak
        const totalQuestions = testResults.correctCount + testResults.incorrectCount + testResults.unattemptedCount;
        const scorePercentage = (testResults.correctCount / totalQuestions) * 100;
        setExamScore(scorePercentage);

        navigate('/dashboard', { 
          replace: true,
          state: { 
            testComplete: true,
            testResults,
            message: 'Test submitted successfully!' 
          }
        });
      } else {
        console.error('Test submission failed:', submitResponse);
        setToastType('error');
        setToastMessage('Failed to complete test submission. Please try again.');
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error submitting test:', error);
      setToastType('error');
      setToastMessage('Error submitting test. Please try again.');
      setShowToast(true);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleLike = (questionIndex) => {
  };

  const handleDislike = (questionIndex) => {
  };

  const handleFavorite = (questionIndex) => {
  };

  const handleSaveProgress = async () => {
    if (!user || !user.email) return;
  
    setSavingProgress(true);
  
    const currentQuestion = questions[currentQuestionIndex];
    const questionData = {
      Index: currentQuestion.Index,
      SelectedOptions: currentQuestion.SelectedOptions,
    };
  
    try {
      const saveResponse = await saveTest(user.email, testId, questionData);
      if (!saveResponse || saveResponse.status !== "success") {
        console.error("Failed to save test progress:", saveResponse);
      } else {
        console.log("Test progress saved successfully.");
      }
    } catch (error) {
      console.error("Error saving test progress:", error);
    } finally {
      setSavingProgress(false);
    }
  };

  const handleNextQuestion = async () => {
    if (testStatus !== 'Completed') {
      await handleSaveProgress();
    }
    setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % questions.length);
  };

  const allQuestionsAnswered = questions.every(
    (question) => question.SelectedOptions && question.SelectedOptions.length > 0
  );

  const currentQuestion = questions[currentQuestionIndex];

  const getOptionClass = (optionIndex, isSelected, isCorrect) => {
    const baseClass = "font-weight-bold";
    const sizeClass = "fs-5";
  
    if (testStatus === "Completed") {
      if (isSelected && isCorrect) return `${baseClass} ${sizeClass} text-success`;
      if (isSelected && !isCorrect) return `${baseClass} ${sizeClass} text-danger`;
      if (!isSelected && isCorrect) return `${baseClass} ${sizeClass} text-success`;
      return sizeClass;
    }
  
    return isSelected ? `${baseClass} ${sizeClass} text-primary` : sizeClass;
  };

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  if (loading) return <p>Loading questions...</p>;
  if (!questions.length) return <p>No questions available.</p>;

  return (
    <Container fluid>
      <AdhyapakAssistant 
        examResult={examScore ? {
          score: examScore,
          testId: testId
        } : null}
        user={user}
      />
      {showToast && (
        <div className={`toast-message ${toastType === 'success' ? 'toast-success' : 'toast-error'}`}>
          {toastMessage}
          <button 
            className="toast-close"
            onClick={() => setShowToast(false)}
            aria-label="Close"
          >
            ×
          </button>
        </div>
      )}
      <Row>
        <Col md={8}>
          <Card className="mb-4">
            <Card.Body>
              <h5>Question {currentQuestion?.Index}</h5>
              <p>{currentQuestion?.Question}</p>
              {currentQuestion?.Options && (
                <Form>
                  {currentQuestion?.Options.map((option, index) => {
                    const isSelected = currentQuestion.SelectedOptions?.includes(index + 1);
                    const isCorrect = currentQuestion.Answers?.includes(index + 1);

                    return (
                      <Form.Check
                        key={index}
                        type="checkbox"
                        label={option}
                        id={`option-${index}`}
                        checked={isSelected}
                        onChange={() => handleOptionSelect(index + 1)}
                        className={getOptionClass(index + 1, isSelected, isCorrect)}
                        disabled={testStatus === "Completed"}
                      />
                    );
                  })}
                </Form>
              )}
              <div className="d-flex justify-content-between mt-4">
                <Button onClick={() => setCurrentQuestionIndex((prev) => prev - 1)} disabled={currentQuestionIndex === 0}>
                  Previous
                </Button>
                <Button onClick={handleNextQuestion} disabled={currentQuestionIndex === questions.length - 1}>
                  {savingProgress ? <Spinner as="span" animation="border" size="sm" /> : 'Next'}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Body>
              <ProgressBar now={(currentQuestionIndex + 1) / questions.length * 100} />
              <div className="d-flex justify-content-center mt-3">
                {questions.map((_, index) => (
                  <Button
                    key={index}
                    variant={isQuestionAnswered(index) ? 'success' : 'outline-secondary'}
                    onClick={() => setCurrentQuestionIndex(index)}
                    className="mx-1"
                  >
                    {index + 1}
                  </Button>
                ))}
              </div>
              <div className="mt-3">
                <Button
                  variant={likes[currentQuestionIndex]?.liked ? 'success' : 'outline-success'}
                  onClick={() => handleLike(currentQuestionIndex)}
                >
                  👍
                </Button>
                <Button
                  variant={likes[currentQuestionIndex]?.disliked ? 'danger' : 'outline-danger'}
                  onClick={() => handleDislike(currentQuestionIndex)}
                >
                  👎
                </Button>
                <Button
                  variant={likes[currentQuestionIndex]?.favorite ? 'warning' : 'outline-warning'}
                  onClick={() => handleFavorite(currentQuestionIndex)}
                >
                  ⭐
                </Button>
              </div>
              <Form.Group className="mt-3">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={notes[currentQuestionIndex] || ''}
                  onChange={(e) => {
                    const updatedNotes = [...notes];
                    updatedNotes[currentQuestionIndex] = e.target.value;
                    setNotes(updatedNotes);
                  }}
                  placeholder="Write your notes here..."
                />
              </Form.Group>
              <Button
                variant="success"
                className="mt-3"
                onClick={handleSubmitTest}
                disabled={!allQuestionsAnswered || submitLoading}
              >
                {submitLoading ? <Spinner as="span" animation="border" size="sm" /> : 'Submit Test'}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ExamTakingPage;
