// src/components/Exam/api.js
import AWS from 'aws-sdk';
import { signApiRequest } from '../../config/awsConfig';

export const fetchExamNames = async () => {
    const apiGateway = new AWS.APIGateway();
    const params = {
        restApiId: '0dzl38b7t9', 
        resourceId: 'ty1mpg', 
        httpMethod: 'GET',
    };

    try {
        const response = await signApiRequest(params);
        const data = JSON.parse(response.body);
        return data.exams;
    } catch (error) {
        console.error('Error fetching exam names:', error);
        // Handle error gracefully
    }
};

export const fetchExamDetails = async (examName) => {
    const apiGateway = new AWS.APIGateway();
    const encodedExamName = encodeURIComponent(examName); // URL-encode the exam name
    const pathWithQueryString = `/examSchema?examName=${encodedExamName}`;
    const params = {
        restApiId: 'p728vtfvu4',
        resourceId: '1krcfl',
        httpMethod: 'GET',
        pathWithQueryString: pathWithQueryString
    };

    try {
        
        const response = await signApiRequest(params);
        const data = JSON.parse(response.body);
        return data; // Or extract specific details as needed
    } catch (error) {
        console.error('Error fetching exam details:', error);
        
    }
};

export const createTest = async (email, testDetails) => {
    const apiGateway = new AWS.APIGateway();
    const userId = encodeURIComponent(email); // URL-encode the email
    const pathWithQueryString = `/examSchema?userId=${userId}`;
    
    const params = {
        restApiId: 'qeo80y41rh',  // Replace with your actual RestApiId
        resourceId: '1rj079',  // Replace with your actual ResourceId
        httpMethod: 'POST',
        pathWithQueryString: pathWithQueryString,
        body: JSON.stringify(testDetails), // Send testDetails as JSON
        headers: {
            'Content-Type': 'application/json',
        }
    };

    try {
        const response = await signApiRequest(params);
        const data = JSON.parse(response.body);
        //console.log("createtest :*****",response)
        return data; // Return the response data
    } catch (error) {
        console.error('Error creating test:', error);
        return {}
    }
};

export const getAllTests = async (emailId) => {
    const apiGateway = new AWS.APIGateway();
    const encodedemailId = encodeURIComponent(emailId); // URL-encode the exam name
    const pathWithQueryString = `/getTest?userId=${encodedemailId}`;
    const params = {
        restApiId: 'uctgzoz3q9',
        resourceId: 'cqg5wwoug5',
        httpMethod: 'GET',
        pathWithQueryString: pathWithQueryString
    };

    try {
        
        const response = await signApiRequest(params);
        const data = JSON.parse(response.body);
        console.log("getAllTests----",data)
        return data; // Or extract specific details as needed
    } catch (error) {
        console.error('Error fetching test details :', error);
        return {}
          
    }
};


export const getTestPayload = async (emailId,TestID) => {
    const apiGateway = new AWS.APIGateway();
    const encodedemailId = encodeURIComponent(emailId); // URL-encode the exam name
    const encodedTestID = encodeURIComponent(TestID);
    const pathWithQueryString = `/getTest?userId=${encodedemailId}&testId=${encodedTestID}`;
    const params = {
        restApiId: '05dnxg0y8a',  // Replace with your actual RestApiId
        resourceId: 'ymzkdutv4h',  // Replace with your actual ResourceId
        httpMethod: 'GET',
        pathWithQueryString: pathWithQueryString
    };

    try {
        
        const response = await signApiRequest(params);
        const data = JSON.parse(response.body);
        console.log("test payload",data)
        return data; // Or extract specific details as needed
    } catch (error) {
        console.error('Error fetching test details :', error);
        return {}
          
    }
};

export const saveTest = async (emailID, TestID, testDetails) => {
    const apiGateway = new AWS.APIGateway();
    const encodedemailId = encodeURIComponent(emailID); // URL-encode the exam name
    const encodedTestID = encodeURIComponent(TestID);
    const pathWithQueryString = `/saveTest?userId=${encodedemailId}&testId=${encodedTestID}`;
    const params = {
        restApiId: 'u01skvkq42',  // Replace with your actual RestApiId
        resourceId: 'n9tag7ped2',  // Replace with your actual ResourceId
        httpMethod: 'PUT',
        pathWithQueryString: pathWithQueryString,
        body: JSON.stringify(testDetails), // Send testDetails as JSON
        headers: {
            'Content-Type': 'application/json',
        }
    };

    //console.log("save test request body:", JSON.stringify(testDetails));

    try {
        const response = await signApiRequest(params);
        
        // Log the full response for debugging
        //console.log("Raw save test response:", response);

        // Handle case where the response is plain text and not JSON
        let data;
        if (response.body && response.body.startsWith("{") && response.body.endsWith("}")) {
            // Try parsing as JSON only if it looks like JSON
            try {
                data = JSON.parse(response.body);
                //console.log("Parsed save test response:", data);
            } catch (jsonError) {
                console.error('Error parsing JSON response:', jsonError);
                console.error('Response body is not valid JSON:', response.body);
                data = { message: "Invalid JSON format returned" }; // Fallback message for invalid JSON
            }
        } else {
            // Handle non-JSON response (e.g., plain text)
            console.error('Response is not JSON, returning plain text:', response.body);
            data = { message: response.body }; // Return the raw response text
        }

        return data; // Return the response (parsed or raw)

    } catch (error) {
        console.error('Save test error:', error);
        return {};  // Return empty object in case of error
    }
};




export const submitTest = async (emailID, TestID) => {
    const apiGateway = new AWS.APIGateway();
    const encodedemailId = encodeURIComponent(emailID); // URL-encode the exam name
    const encodedTestID = encodeURIComponent(TestID);
    const pathWithQueryString = `/saveTest?userId=${encodedemailId}&testId=${encodedTestID}`;
    const params = {
        restApiId: '9snsthg7wd',  // Replace with your actual RestApiId
        resourceId: '08aa9e0og5',  // Replace with your actual ResourceId
        httpMethod: 'PUT',
        pathWithQueryString: pathWithQueryString
    };

    try {
        
        const response = await signApiRequest(params);
        const data = JSON.parse(response.body);
        //console.log("submit test",data)
        return data; // Or extract specific details as needed
    } catch (error) {
        console.error('submit test error :', error);
        return {}
          
    }
};


export const deleteTest = async (emailID, TestID) => {
    const apiGateway = new AWS.APIGateway();
    const encodedemailId = encodeURIComponent(emailID);
    const encodedTestID = encodeURIComponent(TestID);
    const pathWithQueryString = `/deleteTest?userId=${encodedemailId}&testId=${encodedTestID}`;
    const params = {
        restApiId: 'c0y47iwvm2',  // Replace with your actual RestApiId
        resourceId: '39v4v8e3l3',  // Replace with your actual ResourceId
        httpMethod: 'GET',
        pathWithQueryString: pathWithQueryString
    };
    console.log("Raw response:", emailID, TestID);
    try {
        // Send the request and get the response
        const response = await signApiRequest(params);
        //console.log("Raw response:", response); // Log full response for debugging
        
        // Check if the response has a body
        if (!response || !response.body) {
            console.error('No response body received.');
            return { error: 'No response received from the server.' };
        }

        // Check if response is JSON or plain text
        const contentType = response.headers?.['Content-Type'] || '';
        if (contentType.includes('application/json')) {
            const data = JSON.parse(response.body);
            //console.log("Parsed response:", data);
            return data;
        } else {
            console.log("Non-JSON response:", response.body);
            return { message: response.body }; // Returning the plain text message
        }

    } catch (error) {
        console.error('Error during deleteTest API call:', error);
        return { error: 'Error deleting test. Please try again.' };
    }
};




// Newsletter subscription endpoint
export const subscribeToNewsletter = async (email) => {
    try {
        // This is a placeholder implementation
        // TODO: Replace with actual AWS integration
        const mockResponse = await new Promise((resolve) => {
            setTimeout(() => {
                // Simulate API response
                resolve({
                    status: 200,
                    data: {
                        message: 'Successfully subscribed to newsletter',
                        email: email,
                        subscriptionDate: new Date().toISOString(),
                        // Additional fields that match your AWS API structure
                        subscriptionId: Math.random().toString(36).substr(2, 9),
                        status: 'SUBSCRIBED'
                    }
                });
            }, 1000);
        });

        return mockResponse;
    } catch (error) {
        console.error('Newsletter subscription error:', error);
        throw new Error('Failed to subscribe to newsletter');
    }
};

export default {fetchExamNames, fetchExamDetails, createTest, getAllTests, getTestPayload, saveTest, submitTest, deleteTest, subscribeToNewsletter};
