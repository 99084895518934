import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../../assets/css/Header.css'; // Ensure this path is correct

const Header = ({ user, setUser }) => {
    const navigate = useNavigate();
    const navbarToggleRef = useRef(null);

    const handleLogout = () => {
        setUser(null);
        navigate('/');
    };

    const handleDashboardClick = () => {
        if (user) {
            navigate('/dashboard');
        } else {
            navigate('/');
        }
        closeNavbar();
    };

    const closeNavbar = () => {
        if (window.innerWidth < 992) { // lg breakpoint in Bootstrap is 992px
            navbarToggleRef.current?.click();
        }
    };

    return (
        <Navbar className="custom-navbar" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/" onClick={handleDashboardClick} className="navbar-brand">
                    Adhyai
                </Navbar.Brand>
                <Navbar.Toggle ref={navbarToggleRef} aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {user && (
                            <>
                                <Nav.Link as={Link} to="/dashboard" onClick={handleDashboardClick} className="custom-nav-link">Dashboard</Nav.Link>
                                <Nav.Link as={Link} to="/community" onClick={closeNavbar} className="custom-nav-link">Community</Nav.Link>
                                <Nav.Link as={Link} to="/statistics" onClick={closeNavbar} className="custom-nav-link">Statistics</Nav.Link>
                            </>
                        )}
                    </Nav>
                    <Nav className="ms-auto">
                        {user ? (
                            <>
                                <Nav.Link disabled className="custom-nav-link">{user.email}</Nav.Link>
                                <Nav.Link onClick={() => { handleLogout(); closeNavbar(); }} className="custom-nav-link">Logout</Nav.Link>
                            </>
                        ) : (
                            <>
                                <Nav.Link as={Link} to="/login" onClick={closeNavbar} className="custom-nav-link">Login</Nav.Link>
                                <Nav.Link as={Link} to="/signup" onClick={closeNavbar} className="custom-nav-link">Sign Up</Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
