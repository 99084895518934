// Mock API functions to simulate backend calls
// These can be replaced with actual AWS API calls later

export const fetchQuickStats = async () => {
  // Simulating API delay
  await new Promise(resolve => setTimeout(resolve, 300));
  
  return {
    activeDays: 18,
    testsTaken: 24,
    strikeRate: '82%',
    averageScore: '76%',
    timeSpent: '45h',
    questionsAttempted: 480,
  };
};

export const fetchSubjectPerformance = async () => {
  await new Promise(resolve => setTimeout(resolve, 300));
  
  return {
    'Mathematics': { score: 85, improvement: '+5%' },
    'Physics': { score: 78, improvement: '+3%' },
    'Chemistry': { score: 72, improvement: '+7%' }
  };
};

export const fetchRecentExams = async () => {
  await new Promise(resolve => setTimeout(resolve, 300));
  
  return [
    { name: 'Advanced Calculus', score: 85, date: '2d ago' },
    { name: 'Quantum Physics', score: 78, date: '4d ago' },
    { name: 'Organic Chemistry', score: 92, date: '1w ago' }
  ];
};

export const fetchTopicsData = async () => {
  await new Promise(resolve => setTimeout(resolve, 300));
  
  return {
    strongTopics: ['Algebra', 'Trigonometry', 'Organic Chemistry'],
    weakTopics: ['Calculus', 'Electrochemistry', 'Thermodynamics'],
    topicProgress: {
      'Algebra': {percentage: 82, lastActive: '3d ago'},
      'Trigonometry': {percentage: 78, lastActive: '1d ago'},
      'Organic Chemistry': {percentage: 85, lastActive: '2d ago'},
      'Calculus': {percentage: 45, lastActive: '5d ago'},
      'Electrochemistry': {percentage: 38, lastActive: '1w ago'},
      'Thermodynamics': {percentage: 42, lastActive: '4d ago'}
    }
  };
};

export const fetchHeatmapData = async () => {
  await new Promise(resolve => setTimeout(resolve, 300));
  
  // Generate GitHub-style heatmap data with proper weekday alignment
  const getWeekdayOffset = (date) => {
    const day = date.getDay(); // 0 = Sunday
    return day === 0 ? 6 : day - 1; // Convert to Mon-Sun = 0-6
  };

  const months = [];
  const today = new Date();
  
  // Generate 3 months of data
  for (let m = 0; m < 3; m++) {
    const monthDate = new Date(today);
    monthDate.setMonth(today.getMonth() - m);
    const daysInMonth = new Date(
      monthDate.getFullYear(),
      monthDate.getMonth() + 1,
      0
    ).getDate();
    
    const monthData = {
      name: monthDate.toLocaleString('default', { month: 'long' }),
      year: monthDate.getFullYear(),
      days: (() => {
        const daysArray = [];
        const firstDay = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1);
        
        // Add empty cells for alignment
        for(let i = 0; i < getWeekdayOffset(firstDay); i++) {
          daysArray.push(null);
        }

        // Add actual days
        for(let i = 1; i <= daysInMonth; i++) {
          const date = new Date(monthDate);
          date.setDate(i);
          daysArray.push({
            date,
            count: Math.floor(Math.random() * 5)
          });
        }
        
        // Fill remaining cells to complete last week
        while(daysArray.length % 7 !== 0) {
          daysArray.push(null);
        }
        
        return daysArray;
      })()
    };
    months.push(monthData);
  }
  return months.reverse();
};
