import React, { useState, useEffect, useRef } from 'react';
import { sendChatMessage, getChatHistory } from './assistantApi';
import '../../assets/css/AdhyapakAssistant.css';

const TeacherCharacter = ({ mood }) => (
  <svg width="120" height="120" viewBox="0 0 120 120" className="teacher-svg">
    {/* Head */}
    <circle cx="60" cy="60" r="50" fill="#FFD3B6" stroke="#2196f3" strokeWidth="3"/>
    
    {/* Eyes */}
    {mood === 'thinking' ? (
      <>
        <line x1="40" y1="50" x2="50" y2="50" stroke="#333" strokeWidth="3" strokeLinecap="round"/>
        <line x1="70" y1="50" x2="80" y2="50" stroke="#333" strokeWidth="3" strokeLinecap="round"/>
      </>
    ) : (
      <>
        <circle cx="45" cy="50" r="5" fill="#333"/>
        <circle cx="75" cy="50" r="5" fill="#333"/>
      </>
    )}
    
    {/* Eyebrows */}
    <path 
      d={mood === 'thinking' 
        ? "M35 40 Q45 35 55 40" 
        : mood === 'excited'
        ? "M35 38 Q45 33 55 38"
        : "M35 40 Q45 40 55 40"} 
      stroke="#333" 
      strokeWidth="2" 
      fill="none"
    />
    <path 
      d={mood === 'thinking'
        ? "M65 40 Q75 35 85 40"
        : mood === 'excited'
        ? "M65 38 Q75 33 85 38"
        : "M65 40 Q75 40 85 40"} 
      stroke="#333" 
      strokeWidth="2" 
      fill="none"
    />
    
    {/* Mouth */}
    <path 
      d={mood === 'happy' || mood === 'excited'
        ? "M45 75 Q60 85 75 75" // Happy smile
        : mood === 'thinking'
        ? "M50 75 Q60 75 70 75" // Straight line
        : "M45 75 Q60 80 75 75"} // Slight smile
      stroke="#333" 
      strokeWidth="3" 
      fill="none"
    />
    
    {/* Glasses */}
    <circle cx="45" cy="50" r="15" stroke="#2196f3" strokeWidth="2" fill="none"/>
    <circle cx="75" cy="50" r="15" stroke="#2196f3" strokeWidth="2" fill="none"/>
    <line x1="60" y1="50" x2="60" y2="50" stroke="#2196f3" strokeWidth="2"/>
    
    {/* Hair */}
    <path 
      d="M30 35 Q60 10 90 35" 
      stroke="#333" 
      strokeWidth="3" 
      fill="#333"
    />
  </svg>
);

const AdhyapakAssistant = ({ examResult, testCreationData, user }) => {
  const [isVisible, setIsVisible] = useState(() => {
    const savedVisibility = localStorage.getItem('adhyapakVisible');
    return savedVisibility === null ? true : savedVisibility === 'true';
  });
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [mood, setMood] = useState('normal');
  const [isLoading, setIsLoading] = useState(false);
  const chatRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('adhyapakVisible', isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (messages.length === 0) {
      addMessage('assistant', "Hello! I'm Adhyapak, your learning assistant. How can I help you today?");
    }
  }, []);

  useEffect(() => {
    if (examResult) {
      const score = examResult.score;
      let newMood = score >= 80 ? 'excited' : score >= 60 ? 'happy' : 'normal';
      setMood(newMood);
      
      const resultSummary = `${getEncouragement(score)} You scored ${score}%. ${
        score >= 70 
          ? "That's excellent work! Keep up the great effort!" 
          : "Keep practicing, I believe in your potential!"
      }`;
      addMessage('assistant', resultSummary);
    }
  }, [examResult]);

  useEffect(() => {
    if (testCreationData) {
      setMood('thinking');
      const feedback = `I see you're interested in ${testCreationData.topic}! 
        I'm excited to help you learn about ${
          testCreationData.subjects
            .map(s => s.topics.length > 0 ? s.topics.join(', ') : s.name)
            .join(' and ')
        }. Let's make this an engaging learning experience!`;
      addMessage('assistant', feedback);
    }
  }, [testCreationData]);

  const getEncouragement = (score) => {
    const phrases = [
      "Well done!", 
      "Great effort!", 
      "You're making progress!", 
      "Keep going strong!",
      "You're on the right track!"
    ];
    return phrases[Math.floor(Math.random() * phrases.length)];
  };

  const addMessage = (sender, text) => {
    const newMessage = { sender, text, timestamp: new Date() };
    setMessages(prev => [...prev, newMessage]);
    return newMessage;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || !user?.email || isLoading) return;

    setIsLoading(true);
    setMood('thinking');
    const userMessage = addMessage('user', inputMessage);
    setInputMessage('');

    try {
      const response = await sendChatMessage(user.email, inputMessage);
      
      if (response?.message) {
        setMood('happy');
        addMessage('assistant', response.message);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Chat error:', error);
      setMood('normal');
      addMessage('assistant', "I'm here to help you learn and grow! What would you like to know about your studies?");
    } finally {
      setIsLoading(false);
    }
  };

  const handleMouseDown = (e) => {
    if (e.target.closest('.character-container')) {
      setIsDragging(true);
      const chat = chatRef.current;
      const rect = chat.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });

      const handleMouseMove = (e) => {
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        
        // Keep the assistant within reasonable bounds
        const x = Math.min(vw - 300, Math.max(0, e.clientX - dragOffset.x));
        const y = Math.min(vh - 200, Math.max(0, e.clientY - dragOffset.y));
        
        chat.style.left = `${x}px`;
        chat.style.top = `${y}px`;
      };

      const handleMouseUp = () => {
        setIsDragging(false);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  };

  return (
    <>
      {!isVisible && (
        <div className="recall-button-container">
          <button 
            className="recall-adhyapak"
            onClick={() => setIsVisible(true)}
            title="Show Adhyapak"
          >
            👨‍🏫<span>Adhyapak</span>
          </button>
        </div>
      )}

      <div 
        ref={chatRef}
        className={`adhyapak-container ${!isVisible ? 'minimized' : ''}`}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
          display: isVisible ? 'flex' : 'none'
        }}
        onMouseDown={handleMouseDown}
      >
        <div className="character-container">
          <div className="speech-bubble">
            {messages.length > 0 && (
              <div className="message-content">
                {messages[messages.length - 1].text}
              </div>
            )}
          </div>
          <div className={`character ${mood}`} data-mood={mood}>
            <TeacherCharacter mood={mood} />
          </div>
        </div>
        
        <form onSubmit={handleSubmit} className="chat-input">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Ask me anything..."
            disabled={isLoading}
          />
          <button type="submit" disabled={isLoading}>
            {isLoading ? '...' : 'Send'}
          </button>
        </form>

        <button 
          className="close-button"
          onClick={() => setIsVisible(false)}
          title="Hide Adhyapak"
        >
          −
        </button>
      </div>
    </>
  );
};

export default AdhyapakAssistant;
