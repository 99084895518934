import '../../assets/css/LandingPage.css';
import React, { useState } from 'react';
import { Container, Row, Col, Button, Image, Form, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import landingPageImage from '../../assets/images/landing_page_1.jpg';

const LandingPage = () => {
    return (
        <div>
            <HeroSection />
            <FeaturesSection />
            <PersonalizationSection />
            <CommunitySection />
            <NewsletterSection />
            <CallToActionSection />
        </div>
    );
};

const HeroSection = () => (
    <section className="hero-section">
        <Container>
            <Row className="align-items-center">
                <Col md={6}>
                    <h1 className="display-4 fw-bold">Transform Your Learning Journey</h1>
                    <p className="lead">Unlock your potential with personalized learning paths, expert guidance, and a supportive community.</p>
                    <Button variant="primary" as={Link} to="/signup" className="btn-lg px-4 me-3">
                        Get Started Free
                    </Button>
                    <Button variant="outline-primary" as={Link} to="/about" className="btn-lg px-4">
                        Learn More
                    </Button>
                </Col>
                <Col md={6}>
                    <div className="position-relative">
                        <Image src={landingPageImage} fluid rounded className="shadow" alt="Learning Journey" />
                        <p className="text-muted small mt-2"><a href="http://www.freepik.com" className="text-decoration-none">Designed by pch.vector / Freepik</a></p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
);

const FeaturesSection = () => (
    <section className="features-section">
        <Container>
            <h2 className="text-center mb-5 display-5">Why Choose Us?</h2>
            <Row className="g-4">
                <Col md={4}>
                    <div className="feature-card text-center">
                        <i className="fas fa-brain mb-4 display-4 text-primary"></i>
                        <h3>Adaptive Learning</h3>
                        <p className="text-muted">Our AI-powered system adapts to your learning style and pace, ensuring optimal progress.</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="feature-card text-center">
                        <i className="fas fa-tasks mb-4 display-4 text-primary"></i>
                        <h3>Practice & Review</h3>
                        <p className="text-muted">Access comprehensive practice materials and get instant feedback on your performance.</p>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="feature-card text-center">
                        <i className="fas fa-chart-line mb-4 display-4 text-primary"></i>
                        <h3>Progress Tracking</h3>
                        <p className="text-muted">Monitor your improvement with detailed analytics and personalized insights.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
);

const PersonalizationSection = () => (
    <section className="personalization-section">
        <Container>
            <Row className="align-items-center">
                <Col md={6}>
                    <Image src="https://via.placeholder.com/500x400" fluid rounded className="shadow-lg" alt="Personalization" />
                </Col>
                <Col md={6}>
                    <h2 className="display-5 mb-4">Your Path, Your Pace</h2>
                    <p className="lead text-muted mb-4">
                        Every student is unique. Our platform adapts to your learning style, helping you master concepts effectively and efficiently.
                    </p>
                    <ul className="list-unstyled">
                        <li className="mb-3"><i className="fas fa-check text-primary me-2"></i> Personalized study plans</li>
                        <li className="mb-3"><i className="fas fa-check text-primary me-2"></i> Adaptive difficulty levels</li>
                        <li className="mb-3"><i className="fas fa-check text-primary me-2"></i> Progress-based recommendations</li>
                    </ul>
                    <Button variant="primary" as={Link} to="/signup" className="mt-3">
                        Start Learning
                    </Button>
                </Col>
            </Row>
        </Container>
    </section>
);

const CommunitySection = () => (
    <section className="community-section">
        <Container>
            <Row className="align-items-center">
                <Col md={6}>
                    <h2 className="display-5 mb-4">Join Our Learning Community</h2>
                    <p className="lead text-muted mb-4">
                        Connect with fellow learners, share experiences, and grow together in our supportive community.
                    </p>
                    <ul className="list-unstyled">
                        <li className="mb-3"><i className="fas fa-users text-primary me-2"></i> Collaborative learning</li>
                        <li className="mb-3"><i className="fas fa-comment-dots text-primary me-2"></i> Discussion forums</li>
                        <li className="mb-3"><i className="fas fa-graduation-cap text-primary me-2"></i> Peer support</li>
                    </ul>
                    <Button variant="primary" as={Link} to="/community" className="mt-3">
                        Join Community
                    </Button>
                </Col>
                <Col md={6}>
                    <Image src="https://via.placeholder.com/500x400" fluid rounded className="shadow-lg" alt="Community" />
                </Col>
            </Row>
        </Container>
    </section>
);

const NewsletterSection = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/newsletter/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            
            if (response.ok) {
                setStatus('success');
                setEmail('');
            } else {
                setStatus('error');
            }
        } catch (error) {
            setStatus('error');
        }
    };

    return (
        <section className="newsletter-section">
            <Container>
                <Row className="justify-content-center text-center">
                    <Col md={8}>
                        <h3 className="mb-4">Stay Updated with Latest Learning Resources</h3>
                        <p className="text-muted mb-4">
                            Subscribe to our newsletter for weekly tips, study guides, and educational insights.
                        </p>
                        <Form onSubmit={handleSubmit} className="newsletter-form">
                            <InputGroup>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <Button type="submit" variant="primary">
                                    Subscribe
                                </Button>
                            </InputGroup>
                            {status === 'success' && (
                                <div className="success-message">Thank you for subscribing!</div>
                            )}
                            {status === 'error' && (
                                <div className="error-message">Something went wrong. Please try again.</div>
                            )}
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

const CallToActionSection = () => (
    <section className="cta-section">
        <Container className="text-center">
            <h2 className="display-5 fw-bold mb-4">Ready to Excel?</h2>
            <p className="lead mb-4">
                Join thousands of successful students who have transformed their learning experience with us.
            </p>
            <Button variant="light" as={Link} to="/signup" className="btn-lg px-5">
                Start Free Trial
            </Button>
        </Container>
    </section>
);

export default LandingPage;
