import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { validateUser } from './api';

const Login = ({ setUser }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email && password) {
      setIsLoading(true); // Start loading
      try {
        const response = await validateUser(email, { "Password": password });
        if (response === true) {
          // Store more complete user data including authentication status
          setUser({
            email,
            isAuthenticated: true,
            lastLogin: new Date().toISOString()
          });
          navigate('/dashboard');
        } else {
          setError('Invalid email or password');
        }
      } catch (error) {
        setError('An error occurred, please try again');
      } finally {
        setIsLoading(false); // Stop loading after API call completes
      }
    } else {
      setError('Please enter both email and password');
    }
  };

  return (
    <Container fluid className="p-5 bg-light">
      <Row className="justify-content-center">
        <Col md={6}>
          <h2>Login</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  <span className="ms-2">Logging in...</span>
                </>
              ) : (
                'Login'
              )}
            </Button>
          </Form>
          <p className="mt-3">
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
