import React, { useState, useEffect } from 'react';
import '../../assets/css/Statistics.css';
import AdhyapakAssistant from './AdhyapakAssistant';
import {
  fetchQuickStats,
  fetchSubjectPerformance,
  fetchRecentExams,
  fetchTopicsData,
  fetchHeatmapData
} from './statisticsApi';

const Statistics = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const [additionalStats, setAdditionalStats] = useState(null);
  const [subjectPerformance, setSubjectPerformance] = useState({});
  const [recentExams, setRecentExams] = useState([]);
  const [topicsData, setTopicsData] = useState(null);
  const [heatmapData, setHeatmapData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          quickStats,
          subjects,
          exams,
          topics,
          heatmap
        ] = await Promise.all([
          fetchQuickStats(),
          fetchSubjectPerformance(),
          fetchRecentExams(),
          fetchTopicsData(),
          fetchHeatmapData()
        ]);

        setStats({
          activeDays: quickStats.activeDays,
          testsTaken: quickStats.testsTaken,
          strikeRate: quickStats.strikeRate,
        });

        setAdditionalStats({
          averageScore: quickStats.averageScore,
          timeSpent: quickStats.timeSpent,
          questionsAttempted: quickStats.questionsAttempted,
        });

        setSubjectPerformance(subjects);
        setRecentExams(exams);
        setTopicsData(topics);
        setHeatmapData(heatmap);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading || !stats || !additionalStats || !topicsData) {
    return <div className="stats-container">Loading statistics...</div>;
  }

  return (
    <div className="stats-container">
      <AdhyapakAssistant user={user} />
      <div className="stats-header">
        <h2>Learning Progress</h2>
        <div className="time-filter">
          <button className="active">Week</button>
          <button>Month</button>
          <button>Year</button>
        </div>
      </div>

      {/* Quick Stats */}
      <div className="quick-stats">
        <div className="quick-stat">
          <div className="circular-progress" style={{"--progress": `${(stats.activeDays/30)*100}%`}}>
            <span className="progress-icon">📅</span>
            <div className="inner">
              <span className="value">{stats.activeDays}</span>
              <span className="label">Active Days</span>
            </div>
          </div>
        </div>

        <div className="quick-stat">
          <div className="circular-progress" style={{"--progress": `${parseInt(stats.strikeRate)}%`}}>
            <span className="progress-icon">🎯</span>
            <div className="inner">
              <span className="value">{stats.strikeRate}</span>
              <span className="label">Success Rate</span>
            </div>
          </div>
        </div>

        <div className="quick-stat">
          <div className="circular-progress" style={{"--progress": `${parseInt(additionalStats.averageScore)}%`}}>
            <span className="progress-icon">📈</span>
            <div className="inner">
              <span className="value">{additionalStats.averageScore}</span>
              <span className="label">Avg Score</span>
            </div>
          </div>
        </div>

        <div className="stat-summary">
          <div className="summary-item">
            <span className="icon">⏱️</span>
            <div className="details">
              <span className="value">{additionalStats.timeSpent}</span>
              <span className="label">Study Time</span>
            </div>
          </div>
          <div className="summary-item">
            <span className="icon">📝</span>
            <div className="details">
              <span className="value">{additionalStats.questionsAttempted}</span>
              <span className="label">Questions</span>
            </div>
          </div>
          <div className="summary-item">
            <span className="icon">✅</span>
            <div className="details">
              <span className="value">{stats.testsTaken}</span>
              <span className="label">Tests</span>
            </div>
          </div>
        </div>
      </div>

      {/* Performance Overview */}
      <div className="performance-grid">
      <div className="performance-card subjects">
          <h3>Subject Performance</h3>
          <div className="subjects-list">
            {Object.entries(subjectPerformance).map(([subject, data]) => (
              <div key={subject} className="subject-item">
                <div className="subject-header">
                  <span className="name">{subject}</span>
                  <span className="score">{data.score}%</span>
                </div>
                <div className="progress-bar">
                  <div className="fill" style={{width: `${data.score}%`}}></div>
                </div>
                <span className="trend">{data.improvement}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="performance-card recent">
          <h3>Recent Exams</h3>
          <div className="exams-list">
          {recentExams.map((exam, index) => (
              <div key={index} className="exam-item">
                <div className="exam-info">
                  <span className="name">{exam.name}</span>
                  <span className="date">{exam.date}</span>
                </div>
                <div className="exam-score">
                  <div className="score-circle" style={{"--score": `${exam.score}%`}}>
                    {exam.score}%
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Topics Overview */}
      <div className="topics-overview">
        <div className="topics-grid">
          <div className="topics-card strong">
            <h3>Strong Topics</h3>
            <div className="topics-list">
              {topicsData.strongTopics.map((topic, index) => (
                <div key={index} className="topic-chip">
                  <span className="name">{topic}</span>
                  <span className="score">{topicsData.topicProgress[topic].percentage}%</span>
                </div>
              ))}
            </div>
          </div>
          <div className="topics-card weak">
            <h3>Need Practice</h3>
            <div className="topics-list">
              {topicsData.weakTopics.map((topic, index) => (
                <div key={index} className="topic-chip">
                  <span className="name">{topic}</span>
                  <span className="score">{topicsData.topicProgress[topic].percentage}%</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Activity Heatmap */}
      <div className="heatmap-container">
        <h3><span className="icon">📅</span> Activity Overview</h3>
        <div className="heatmap-grid">
          {heatmapData.map((month) => (
            <div key={`${month.year}-${month.name}`} className="heatmap-month-section">
              <div className="month-header">{month.name} {month.year}</div>
              <div className="heatmap-month-grid">
                {month.days.map((day, dayIndex) => {
                  if (!day) return <div key={dayIndex} className="heatmap-day-empty" />;
                  
                  const dayDate = day.date.toLocaleDateString('en-IN', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                  });
                  return (
                    <div
                      key={dayIndex}
                      className="heatmap-day-cell"
                      style={{ 
                        backgroundColor: `var(--heatmap-${day.count})`,
                        border: day.count > 0 ? '1px solid rgba(0,0,0,0.05)' : 'none'
                      }}
                      data-count={day.count}
                    >
                      <div className="heatmap-tooltip">
                        {day.count} test{day.count !== 1 ? 's' : ''} on {dayDate}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <div className="heatmap-legend">
          <div className="legend-item">
            <div className="legend-color" style={{backgroundColor: 'var(--heatmap-0)'}}></div>
            <span>0</span>
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{backgroundColor: 'var(--heatmap-1)'}}></div>
            <span>1</span>
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{backgroundColor: 'var(--heatmap-2)'}}></div>
            <span>2</span>
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{backgroundColor: 'var(--heatmap-3)'}}></div>
            <span>3</span>
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{backgroundColor: 'var(--heatmap-4)'}}></div>
            <span>4+</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
